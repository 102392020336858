<template>
  <div sign-up-country>
    <h2 class="title"> {{ $t(title) }}
      <span>{{ $t(subTitle) }}</span>
    </h2>
    <p v-if="invite">{{ $t('signUp.inviteFrom', { invite }) }}</p>
    <CountryDropSelect v-if="showCountry" @input="selected" @block="setBlock" name="Step1_Select_Country_View" class="signup-country-view" />
    <p v-if="description" class="channel-guide">{{ $t(description) }}</p>
    <p v-if="errorMsg" class="block-guide">{{ errorMsg }}</p>
    <div class="btn-holder">
      <ProgressButton name="Step2_Select_Country_Button" class="signup-country-btn" :disabled="!model" :button-name="buttonName" :progress="isProgress" :options="buttonOptions" @click="confirm" />
    </div>
    <FromLanding v-if="useLanding" />
  </div>
</template>

<script>

import _ from 'lodash';
import { state } from '@shared/utils/storeUtils.mjs';
import { isExcludeGgpass } from '@/utils/baseUtil';
import { getSite } from '@/constants/base/signup/signUpSiteMap';
import { sendSignupReadyToBrandWeb } from '@/utils/webSiteUtil';
import ProgressButton from '@/views/components/common/ProgressButton.vue';
import CountryDropSelect from '@/views/components/common/CountryDropSelect.vue';
import FromLanding from '@/views/components/pages/sign-up/asset/FromLanding.vue';
import RoMessageModal from '@/views/components/extra/RoMessageModal.vue';
import { brandIds, gpBrandIds, siteIds } from '@/constants/base/siteMap.mjs';
import { baseList } from '@/constants/base/siteMap.mjs';

export default {
  name: 'SignUpCountry',
  components: { CountryDropSelect, ProgressButton, FromLanding },
  lexicon: 'signUp.country',
  data() {
    return {
      model: null,
      isProgress: false,
      blockLink: null,
      buttonName: 'confirm',
      buttonOptions: null,
      errorMsg: null,
    };
  },
  computed: {
    config: state('config', 'info'),
    env: state('env', 'env'),
    site: state('env', 'site'),
    brand: state('env', 'brand'),
    gpBrand: state('env', 'gpBrand'),
    brandSignup: state('env', 'brandSignup'),
    skipSignupPersonalInfo: state('env', 'skipSignupPersonalInfo'),
    invite: state('query', 'invite'),
    originId: state('query', 'originId'),
    landingMode: state('query', 'landingMode'),
    isFromLanding: state('query', 'isFromLanding'),
    channelingName: state('query', 'channelingName'),
    signedPw: state('user', 'signedPw'),
    signedEmail: state('user', 'signedEmail'),
    useLanding() {
      return this.isFromLanding && [brandIds.GGPUKE, brandIds.EVPUKE].includes(this.brand);
    },
    isNatural8GpBrand() {
      return this.gpBrand === gpBrandIds.NATURAL8;
    },
    title() {
      return !isExcludeGgpass(this.site) ? '_.ggpassTitle' : '_.title';
    },
    subTitle() {
      return !isExcludeGgpass(this.site) ? '' : '_.subTitle';
    },
    description() {
      if (this.landingMode === 'warp' || this.channelingName) return !isExcludeGgpass(this.site) ? '_.ggpassDescription' : 'signUp.country.channelingGuide';
      return '';
    },
    showCountry() {
      if (this.brandSignup) return !this.$route.query.country;
      return true;
    },
  },
  methods: {
    setBlock(item) {
      this.blockLink = item.link;
      this.buttonName = 'signUp.visit';
      this.buttonOptions = { country: item.label };
    },
    selected(value) {
      this.blockLink = null;
      this.model = value;
      this.buttonName = 'confirm';
    },
    findIndexCountries(site, model) {
      return site.countries?.split(',').find(c => c === model);
    },
    includesDomain(site, host) {
      const { VUE_APP_ENV, VUE_APP_BRAND } = process.env;
      const isLocal = VUE_APP_ENV === 'local';
      const isCasino = host.includes('casino');

      if (isLocal) return VUE_APP_BRAND.toLowerCase() === (isCasino ? site.brandAlias.casino : site.brandAlias.default || site.brandAlias).toLowerCase();
      return (isCasino ? site.domain.casino : _.isString(site.domain) ? site.domain : (site.domain.default || ''))?.split(',').find(i => host.includes(i));
    },
    fixedSite() {
      const host = location.host;
      const querySiteId = this.$route.query.siteId;
      if (!querySiteId || querySiteId === 'none' || this.landingMode === 'warp' || this.landingMode === 'landing') {
        let countrySite = baseList.find(site => this.includesDomain(site, host) && this.findIndexCountries(site, this.model));
        if (!countrySite) countrySite = getSite({ domain: host, site: querySiteId || process.env.VUE_APP_SITE}); // 로컬일 경우로 간주하고 VUE_APP_SITE 전달

        if (countrySite) {
          this.$store.commit('env/setGpSite', countrySite.gpSite);
          this.$store.commit('env/setSite', countrySite.site);
        }
      }
    },
    checkBlockList() {
      if (this.blockLink) {
        window.open(this.blockLink, '_blank');
        return false;
      }
      return true;
    },
    async checkAvailableLocation(country) {
      const r = /** @type {{IsAllowed: boolean} || {error: boolean, comment: string}} */ await this.$services.sign.checkAvailableLocation({ countryCode: country });
      if (r.IsAllowed) return true;
      if (r.desc === 'GGPRO under maintenance') {
        this.$modal(RoMessageModal);
        return false;
      } else if (r.error) {
        this.$modal(RoMessageModal);
        this.errorMsg = this.$t(r.comment);
        return false;
      }
      await this.replaceRouteName('RestrictLocation', null, null);
      return false;
    },
    async checkCountryLocation() {
      return !this.checkBlockList() || !await this.checkAvailableLocation(this.model);
    },
    async confirm() {
      if (await this.checkCountryLocation()) return;
      this.fixedSite();
      this.isProgress = true;
      this.$store.commit('user/setCountryCode', this.model);
      await this.routeName('SignUpPersonalInfo', null, { ...this.$route.query, country: this.model });
    },
  },
  async mounted() {
    if (this.brandSignup) {
      this.model = this.$route.query.country;

      let skipCountryCode;
      const countrySite = baseList.filter(site => (this.$route.query.siteId === siteIds.GGPCOM ? [siteIds.GGPCOM, siteIds.GGPCW].includes(site.site) : this.$route.query.siteId === site.site)).find(site => this.findIndexCountries(site, this.model));
      if (countrySite) {
        this.$store.commit('env/setGpSite', countrySite.gpSite);
        this.$store.commit('env/setSite', countrySite.site);
        skipCountryCode = this.findIndexCountries(countrySite, this.model);
      }

      if (!this.model || this.model !== skipCountryCode) {
        this.$store.commit('env/setSkipSignupPersonalInfo', false);
        await this.replaceRouteName('SignUpCountry', null, { ...this.$route.query, country: undefined });

        // 끝난 후 post message 미전달로 id, pw정보가 없을 경우 대기하기 위한 post message 전달 및 딤드 interval 처리
        if (!this.signedEmail || !this.signedPw) {
          await sendSignupReadyToBrandWeb(this);
          let intervalId = setInterval(() => {
            if (!this.$checkModal()) {
              this.$block();
              clearInterval(intervalId);
              intervalId = null;
            }
          }, 500);
        }
      } else {
        if (await this.checkCountryLocation()) return;

        this.$store.commit('env/setSkipSignupPersonalInfo', true);
        this.$store.commit('user/setCountryCode', this.model);
        await this.routeName('SignUpPersonalInfo', null, { ...this.$route.query, country: this.model });
      }
    } else if (this.site === siteIds.SEVENXL) {
      this.model = 'IL';
      await this.confirm();
    } else if (this.site === siteIds.GGPCW) this.$store.commit('env/setSite', siteIds.GGPCOM);
  }
};
</script>

<style lang="less">
@import '@/less/proj.less';
[sign-up-country] {
  .title { .tc(); .lh(32); }
  > p { .fs(16); .mb(20); .c(@c-text-desc) }
  .channel-guide { .mt(16); }
  .block-guide { .mt(16); .c(@c-base-red); }
  .btn-holder { .mt(100); }
}
</style>
